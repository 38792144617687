<!-- KUDOS TO DEV -->
<!-- look for *ngFor it is sometimes used with keyvalue pipe, as it was proposed in old angularJs project  :) thanks me later -->
<!-- Look for 3 point and  5 point report saperatly the HTML might be messed up for some instinct -->

<!-- 3 point -->
<div *ngIf="isThreePointReport" style="background: #ccc">
  <div class="responsiveReportGrid">
    <!-- page1 -->
    <div class="ReportGrid">
      <div class="page1" *ngIf="isPageOne">
        <div class="RTopLeft"></div>
        <div class="RTopRight"></div>
        <div class="ReportHead">
          <div class="RLogoGrid">
            <img src="assets/images/reports/Rlogo.svg" />
          </div>
          <!--  RLogoGrid  -->
          <div class="RDateGrid">
            <h2>
              <span>Date of Assessment</span>
              {{ viewReport?.report_data?.assessment_date }}
            </h2>
          </div>
          <!--  RDateGrid  -->
          <div class="clearfix"></div>
          <div class="RCandidateIm">
            <img *ngIf="getVerdictImage(viewReport, 'Unlikely to succeed')"
              src="assets/images/reports/clip_patch-02.png" style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Restricted likelihood of success')"
              src="assets/images/reports/clip_patch-04.png" style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Likely to succeed')" src="assets/images/reports/clip_patch-01.png"
              style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Very Likely to succeed')"
              src="assets/images/reports/clip_patch-03.png" style="width: 200px; margin-top: 5px" />
          </div>
          <!-- RCandidateIm  -->
          <div class="RCandidateBox">
            <div class="RCandidateInfo">
              <h2>
                <span>Name of Candidate</span>{{ viewReport?.report_data?.candidate_first_name }}
                {{ viewReport?.report_data?.candidate_last_name }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Title</span>{{ viewReport?.report_data?.job_title }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Report</span>{{ getReportName(viewReport?.report_data?.job_type) }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
          </div>
          <!--  RCandidateBox  -->
          <div class="clearfix"></div>
          <div [ngClass]="{ 'reportheading-wrapper-div': true }">
            <!-- For All Job Types -->
            <!-- [ngClass]="headingAlignment(hideOrgComp, viewReport?.report_data?.profile_name, viewReport?.comp_framework)" -->
            <div class="RBoxGrid" *ngIf="viewReport?.report_data?.title != '0NA'" [ngClass]="
                headingAlignment(
                  hideOrgComp,
                  viewReport?.report_data?.profile_name,
                  viewReport?.comp_framework
                )
              ">
              <h2 *ngIf="
                  viewReport?.report_data?.job_type != 'Organisational Match'
                ">
                {{ viewReport?.report_data?.title }}
              </h2>
              <h2 *ngIf="
                  viewReport?.report_data?.job_type == 'Organisational Match'
                ">
                {{ viewReport?.report_data?.response.coverpage_name }}
              </h2>
              <p *ngIf="
                  viewReport?.report_data?.job_type != 'Organisational Match'
                ">
                {{ viewReport?.report_data?.coverpage_subtext }}
              </p>
              <p *ngIf="
                  viewReport?.report_data?.job_type == 'Organisational Match'
                ">
                {{
                viewReport?.report_data?.response.orgcompetency_coverpage_text
                }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'Low'
                  ">
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'Medium'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ===
                    'High'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div class="Black-Star"></div>
              <div class="White-Star"></div> -->
                <!-- <div [ngClass]="getStarClass(viewReport?.report_data?.response.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.report_data?.response.overall_star_average_rating)"></div> -->
                <div class="RLow">
                  {{ viewReport?.report_data?.response.overall_average_rating }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Managerial Style in case of Customised Profile Fit -->
            <div class="RBoxGrid" [ngClass]="hideOrgComp ? 'floatLeft' : 'floatLeft'" *ngIf="
                viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
                viewReport?.report_data?.profile_name !=
                  'Individual Contributor'
              ">
              <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
              <p *ngIf="
                  viewReport?.report_data?.job_type == 'Customised Profile Fit'
                ">
                {{ viewReport?.report_data?.mparam_response.coverpage_subtext }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Low'
                  ">
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Medium'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'High'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div [ngClass]="getStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating)"></div> -->
                <!--div [ngClass]="{
                'White-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='Low',
                'White-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='Medium',
                'Black-Star': viewReport?.report_data?.mparam_response.overall_average_rating=='High'
              }"></div-->
                <div class="RLow">
                  {{
                  viewReport?.report_data?.mparam_response
                  .overall_average_rating
                  }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Organizatinal competency in case of Customised Profile Fit -->
            <div class="RBoxGrid" *ngIf="hideOrgComp" [ngClass]="hideOrgComp ? 'floatLeft' : ''">
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
              <p>
                {{
                getReplacedText(
                viewReport?.comp_framework?.orgcompetency_coverpage_text
                )
                }}
              </p>
              <div style="float: left; width: 99%; margin-left: 5px">
                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating === 'Low'
                  ">
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'Medium'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'High'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <div class="Black-Star"></div>
              <div [ngClass]="getStarClass(viewReport?.comp_framework?.overall_star_average_rating, 'Medium')"></div>
              <div [ngClass]="getHighStarClass(viewReport?.comp_framework?.overall_star_average_rating)"></div> -->
                <!--div [ngClass]="{
                'White-Star': viewReport?.comp_framework?.overall_average_rating=='Low',
                'White-Star': viewReport?.comp_framework?.overall_average_rating=='Medium',
                'Black-Star': viewReport?.comp_framework?.overall_average_rating=='High'
              }"></div-->
                <div class="RLow">
                  {{ viewReport?.comp_framework?.overall_average_rating }}
                </div>
              </div>
            </div>
            <!--  RBoxGrid  -->
          </div>
          <div class="clearfix"></div>
          <div class="RBotBox">
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Unlikely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Unlikely to succeed')">This candidate is</span>-->
                Unlikely to succeed
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Restricted likelihood of success')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Restricted likelihood of success')">This candidate is</span> -->
                Restricted Likelihood of Success
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Likely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Likely to succeed') ">This candidate is</span> -->
                Likely to succeed
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Very Likely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Very Likely to succeed')">This candidate is</span> -->
                Very likely to succeed
              </div>
            </a>
            <div class="clearfix"></div>
            <div class="report-legend-div">
              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">0</span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[0].maxValue}} </span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[1].maxValue}}</span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-4-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[2].maxValue}}</span>
                </div>
                <div class="legend-4-bar">
                </div>
                <div class="legend-4-box">
                  <span class="legend-text-font">100</span>
                </div>
              </div>
            </div>
            <!-- legen div end -->
          </div>
          <!--  RBotBox  -->
        </div>
        <!--  ReportHead  -->
      </div>
      <!--  ReportGrid  -->

      <div class="clearfix"></div>

      <!-- page2 -->
      <div class="ReportGrid page2">
        <div class="RTopLeftBox" *ngIf="viewReport?.report_data?.title != '0NA'"></div>
        <div class="RTopRightBox" *ngIf="viewReport?.report_data?.title != '0NA'"></div>
        <div class="clearfix" *ngIf="viewReport?.report_data?.title != '0NA'"></div>

        <div class="ManagerialBox" *ngIf="viewReport?.report_data?.title != '0NA'">
          <div class="MBLeft" *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'">
            <h2>{{ viewReport?.report_data?.title }}</h2>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Low'
              ">
              {{
              getReplacedText(viewReport?.report_data?.section_subtext_low)
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Medium'
              ">
              {{
              getReplacedText(viewReport?.report_data?.section_subtext_medium)
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'High'
              ">
              {{
              getReplacedText(viewReport?.report_data?.section_subtext_high)
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <div class="MBLeft" *ngIf="viewReport?.report_data?.job_type == 'Organisational Match'">
            <h2 *ngIf="
                viewReport?.report_data?.job_type != 'Organisational Match'
              ">
              {{ viewReport?.report_data?.title }}
            </h2>
            <h2 *ngIf="
                viewReport?.report_data?.job_type == 'Organisational Match'
              ">
              {{ viewReport?.report_data?.response.coverpage_name }}
            </h2>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Low'
              ">
              {{
              getReplacedText(
              viewReport?.report_data?.response.section_subtext_low
              )
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'Medium'
              ">
              {{
              getReplacedText(
              viewReport?.report_data?.response.section_subtext_medium
              )
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.response.overall_average_rating ==
                'High'
              ">
              {{
              getReplacedText(
              viewReport?.report_data?.response.section_subtext_high
              )
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <div class="MBRight_three_scale">
            <div class="StarBox_three_scale">
              <div *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'Low'
                ">
                <div class="Black-Star"></div>
                <div class="White-Star"></div>
                <div class="White-Star"></div>
              </div>

              <div *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'Medium'
                ">
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
                <div class="White-Star"></div>
              </div>

              <div *ngIf="
                  viewReport?.report_data?.response.overall_average_rating ===
                  'High'
                ">
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
                <div class="Black-Star"></div>
              </div>
              <!-- <span class="BlackStar"></span>
              <span [ngClass]="getStarClass(viewReport?.report_data?.response.overall_star_average_rating, 'Medium', 'span')"></span>
              <span [ngClass]="getWhiteStar(viewReport?.report_data?.response.overall_star_average_rating)"></span> -->
              <div class="clearfix"></div>
            </div>
            <!--  StarBox  -->
            <div class="MediumBox_three_scale">
              {{ viewReport?.report_data?.response.overall_average_rating }}
            </div>
          </div>
          <!--  MBRight  -->
        </div>
        <!--  ManagerialBox -->

        <div class="clearfix"></div>
        <div *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'">
          <div class="CoreJob" *ngFor="
              let candidateActivity of viewReport?.report_data?.response
                .response | keyvalue
            ">
            <!-- TODO check here -->
            <h2 *ngIf="viewReport?.report_data?.title != 'Managerial Ability'">
              {{ candidateActivity.key }}
            </h2>
            <div class="CoreJobBox" *ngFor="
                let activity of candidateActivity.value
                  | orderBy: 'activity_name'
              ">
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div [ngClass]="{
                        GraySmallCircle: activity.rating != 'Low',
                        RedCircle: activity.rating == 'Low'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle: activity.rating != 'Medium',
                        YellowCircle: activity.rating == 'Medium'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle: activity.rating != 'High',
                        GreenCircle: activity.rating == 'High'
                      }"></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">{{ activity.rating }}</div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activity.activity_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->

              <div class="HD-Info">
                {{ getReplacedText(activity.ratingtext) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>

        <!-- Job Type == Organsational Match -->
        <div class="ReportGrid" *ngFor="
            let orgdetail of viewReport?.report_data?.response.org_data
              | orderBy: 'org_name';
            let i = index
          ">
          <div class="PeopleOriGrid" [ngClass]="i == 0 ? 'marginTop60' : ''">
            <div class="POriBox POBlanck">
              <div class="POriBoxLeft">
                <h3>{{ orgdetail.org_name }}</h3>
                <div class="POriBox-Info">
                  {{ getReplacedText(orgdetail.rating_text) }}
                </div>
              </div>
              <!-- POriBoxLeft -->
              <div class="HD-Option-Grid">
                <div class="CircleBox">
                  <div [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'Low',
                      RedCircle:
                        orgdetail.org_competency.overall_average_rating == 'Low'
                    }"></div>
                  <div [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'Medium',
                      YellowCircle:
                        orgdetail.org_competency.overall_average_rating ==
                        'Medium'
                    }"></div>
                  <div [ngClass]="{
                      GraySmallCircle:
                        orgdetail.org_competency.overall_average_rating !=
                        'High',
                      GreenCircle:
                        orgdetail.org_competency.overall_average_rating ==
                        'High'
                    }"></div>
                </div>
                <!--  CircleBox  -->
                <div class="CircleBoxMed">
                  {{ orgdetail.org_competency.overall_average_rating }}
                </div>
              </div>
              <!--  HD-Option-Grid  -->
            </div>
            <!--  POriBox  -->
            <div class="POriBox" *ngFor="
                let subcompetency of orgdetail.org_competency.response
                  | orderBy: 'activity_name'
              ">
              <div class="POriBoxLeft">
                <h2>{{ subcompetency.activity_name }}</h2>
                <div class="POriBox-Info">
                  {{ getReplacedText(subcompetency.ratingtext) }}
                </div>
              </div>
              <!-- POriBoxLeft -->
              <div class="HD-Option-Grid">
                <div class="CircleBox">
                  <div [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'Low',
                      RedCircle: subcompetency.rating == 'Low'
                    }"></div>
                  <div [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'Medium',
                      YellowCircle: subcompetency.rating == 'Medium'
                    }"></div>
                  <div [ngClass]="{
                      GraySmallCircle: subcompetency.rating != 'High',
                      GreenCircle: subcompetency.rating == 'High'
                    }"></div>
                </div>
                <!--  CircleBox  -->
                <div class="CircleBoxMed">{{ subcompetency.rating }}</div>
              </div>
              <!--  HD-Option-Grid  -->
            </div>
            <!--  POriBox  -->
          </div>
          <!--  PeopleOriGrid  -->
        </div>
        <!--  Organsational Match  -->

        <!-- Managerial Ability in case of Customised Job Fit -->
        <div class="ReportGrid page2" *ngIf="
            viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
            viewReport?.report_data?.profile_name != 'Individual Contributor'
          ">
          <div class="RTopLeftBox"></div>
          <div class="RTopRightBox"></div>
          <div class="clearfix"></div>
          <div class="ManagerialBox" style="height: auto">
            <div class="MBLeft">
              <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
              <p *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'Low'
                ">
                {{
                viewReport?.report_data?.mparam_response.section_subtext_low
                }}
              </p>
              <p *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'Medium'
                ">
                {{
                viewReport?.report_data?.mparam_response
                .section_subtext_medium
                }}
              </p>
              <p *ngIf="
                  viewReport?.report_data?.mparam_response
                    .overall_average_rating == 'High'
                ">
                {{
                viewReport?.report_data?.mparam_response.section_subtext_high
                }}
              </p>
            </div>
            <!--  MBLeft  -->
            <div class="MBRight_three_scale">
              <div class="StarBox_three_scale">
                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Low'
                  ">
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'Medium'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.report_data?.mparam_response
                      .overall_average_rating === 'High'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <!-- <span class="BlackStar"></span>
                <span [ngClass]="getStarClass(viewReport?.report_data?.mparam_response.overall_star_average_rating, 'Medium', 'span')"></span>
                <span [ngClass]="getWhiteStar(viewReport?.report_data?.mparam_response.overall_star_average_rating)"></span> -->
                <div class="clearfix"></div>
              </div>
              <!--  StarBox  -->
              <div class="MediumBox_three_scale">
                {{
                viewReport?.report_data?.mparam_response
                .overall_average_rating
                }}
              </div>
            </div>
            <!--  MBRight  -->
          </div>
          <!--  ManagerialBox -->
          <div class="clearfix"></div>
          <div class="CoreJob" style="margin-top: 60px">
            <!-- TODO check it here -->
            <div class="CoreJobBox" *ngFor="
                let activityComp of viewReport?.report_data?.mparam_response
                  .response | orderBy: 'activity_name'
              ">
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'Low',
                        RedCircle: activityComp.rating == 'Low'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'Medium',
                        YellowCircle: activityComp.rating == 'Medium'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle: activityComp.rating != 'High',
                        GreenCircle: activityComp.rating == 'High'
                      }"></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">{{ activityComp.rating }}</div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activityComp.activity_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->
              <div class="HD-Info">
                {{ getReplacedText(activityComp.ratingtext) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>

        <!-- Managerial Style in case of Customised Job Fit -->
        <div class="DelegativeGrid" *ngIf="
            viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
            viewReport?.report_data?.profile_name != 'Individual Contributor'
          " style="padding-top: 18px">
          <h2>
            {{
            viewReport?.report_data?.working_style['Managerial Style']
            .main_heading
            }}
          </h2>
          <div class="DelegativeBox">
            <p>
              {{
              getReplacedText(
              viewReport?.report_data?.working_style['Managerial Style']
              .main_sub_text
              )
              }}
            </p>
            <p
              [innerHTML]="
                safeHtml( viewReport?.report_data?.working_style['Managerial Style'][ 'Leadership (democratic)'  ]?.sub_heading_description)">
            </p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (transformational)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (Laissez Faire)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Managerial Style'][
                    'Leadership (autocratic)'
                  ]?.sub_heading_description
                )
              "></p>
          </div>
        </div>

        <!-- Organizatonl competencies in case of job fit and campus Recruitment-->
        <div class="ReportGrid page2" *ngIf="hideOrgComp">
          <div class="RTopLeftBox"></div>
          <div class="RTopRightBox"></div>
          <div class="clearfix"></div>
          <div class="ManagerialBox" style="height: auto">
            <div class="MBLeft">
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
              <p *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'Low'
                ">
                {{
                getReplacedText(
                viewReport?.comp_framework?.section_subtext_low
                )
                }}
              </p>
              <p *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'Medium'
                ">
                {{
                getReplacedText(
                viewReport?.comp_framework?.section_subtext_medium
                )
                }}
              </p>
              <p *ngIf="
                  viewReport?.comp_framework?.overall_average_rating == 'High'
                ">
                {{
                getReplacedText(
                viewReport?.comp_framework?.section_subtext_high
                )
                }}
              </p>
            </div>
            <!--  MBLeft  -->
            <div class="MBRight_three_scale">
              <div class="StarBox_three_scale">
                <!-- <span class="BlackStar"></span>
                <span [ngClass]="getStarClass(viewReport?.comp_framework?.overall_star_average_rating, 'Medium', 'span')"></span>
                <span [ngClass]="getWhiteStar(viewReport?.comp_framework?.overall_star_average_rating)"></span> -->

                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating === 'Low'
                  ">
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'Medium'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="White-Star"></div>
                </div>

                <div *ngIf="
                    viewReport?.comp_framework?.overall_average_rating ===
                    'High'
                  ">
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                  <div class="Black-Star"></div>
                </div>

                <div class="clearfix"></div>
              </div>
              <!--  StarBox  -->
              <div class="MediumBox_three_scale">
                {{ viewReport?.comp_framework?.overall_average_rating }}
              </div>
            </div>
            <!--  MBRight  -->
          </div>
          <!--  ManagerialBox -->
          <div class="clearfix"></div>

          <div class="CoreJob" style="margin-top: 60px">
            <!-- <h2> {{ candidateActivity.org_name }} </h2> -->
            <!-- {{candidateActivity}} -->
            <div class="CoreJobBox" *ngFor="
                let activityComp of viewReport?.comp_framework?.org_data
                  | orderBy: 'org_name'
              ">
              <div class="HeddingGrid">
                <div class="HD-Option-Grid">
                  <div class="CircleBox">
                    <div [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'Low',
                        RedCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'Low'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'Medium',
                        YellowCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'Medium'
                      }"></div>
                    <div [ngClass]="{
                        GraySmallCircle:
                          activityComp.org_competency.overall_average_rating !=
                          'High',
                        GreenCircle:
                          activityComp.org_competency.overall_average_rating ==
                          'High'
                      }"></div>
                  </div>
                  <!--  CircleBox  -->
                  <div class="CircleBoxMed">
                    {{ activityComp.org_competency.overall_average_rating }}
                  </div>
                </div>
                <!--  HD-Option-Grid  -->
                <div class="HD-Title">{{ activityComp.org_name }}</div>
                <!--  HD-Title  -->
              </div>
              <!--  HeddingGrid  -->
              <div class="HD-Info" *ngIf="
                  activityComp.org_competency.overall_average_rating == 'High'
                ">
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
              <div class="HD-Info" *ngIf="
                  activityComp.org_competency.overall_average_rating == 'Medium'
                ">
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
              <div class="HD-Info" *ngIf="
                  activityComp.org_competency.overall_average_rating == 'Low'
                ">
                {{ getReplacedText(activityComp.rating_text) }}
              </div>
            </div>
            <!--  CoreJobBox -->
          </div>
          <!--  CoreJob  -->
        </div>
      </div>

      <!-- Working Style Section to starts here -->
      <div class="ReportGrid" style="padding-top: 40px"
        *ngIf="viewReport?.report_data?.job_type == 'Customised Profile Fit'">
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox">
          <h2 class="ManagHedding">
            {{ viewReport?.report_data?.working_style.name }}
          </h2>
          <p class="ManagInfo">
            {{ viewReport?.report_data?.working_style.cover_page_text }}
          </p>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>
        <div class="DelegativeGrid" [ngClass]="
            viewReport?.report_data?.job_type != 'Organisational Match'
              ? 'marginTop60'
              : ''
          ">
          <ng-container *ngIf="viewReport?.report_data?.working_style['Key Driver']">
            <h2>
              {{
              viewReport?.report_data?.working_style['Key Driver'].main_heading
              }}
            </h2>
            <div class="DelegativeBox" style="margin-top: -8px">
              <p>
                {{
                getReplacedText(
                viewReport?.report_data?.working_style['Key Driver']
                .main_sub_text
                )
                }}
              </p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (social)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (self growth)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (novelty)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (prestige)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (freedom)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (aesthetic)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (goal-oriented)'
                  ]?.sub_heading_description
                  
                )
              "></p>
              <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Key Driver'][
                    'Motivation (reward)'
                  ]?.sub_heading_description
                  
                )
              "></p>
            </div>
          </ng-container>
          <!-- DelegativeBox  -->
          <p></p>
          <p></p>
          <h2 style="padding-top: 20px">
            {{
            viewReport?.report_data?.working_style['Influencing Style']
            .main_heading
            }}
          </h2>
          <div class="DelegativeBox" style="margin-top: -8px">
            <p>
              {{
              getReplacedText(
              viewReport?.report_data?.working_style['Influencing Style']
              .main_sub_text
              )
              }}
            </p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EPL)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EP)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (PL)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (EL)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (E)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (P)'
                  ]?.sub_heading_description
                )
              "></p>
            <p [innerHTML]="
                safeHtml(
                  viewReport?.report_data?.working_style['Influencing Style'][
                    'Influencing Style (L)'
                  ]?.sub_heading_description
                )
              "></p>
          </div>
          <!-- DelegativeBox  -->

          <!-- <p></p>       <p></p>
        <h2 style="padding-top:20px;">{{viewReport?.report_data?.working_style['Approach to Work'].main_heading}}</h2>
        <div class="DelegativeBox" style="margin-top:-8px;">
            <p> {{ getReplacedText(viewReport?.report_data?.working_style['Approach to Work'].main_sub_text) }}</p>
            <p [innerHTML]="safeHtml(viewReport?.report_data?.working_style['Approach to Work']['scatter_data_first'].description, 'work_approach')"></p>
            <p [innerHTML]="safeHtml(viewReport?.report_data?.working_style['Approach to Work']['scatter_data_second'].description, 'work_approach')"></p>
        </div>  -->
          <!-- DelegativeBox  -->
        </div>
        <!--  DelegativeGrid  -->
      </div>
      <!-- Working Style Section to end here -->
    </div>
    <div class="container-image" *ngIf="companyData.isProctoring">
      <div class="image-grid">
        <div *ngFor="let image of images">
          <img src="{{ image }}" class="proctor-image" />
        </div>
      </div>
    </div>
    <ng-container *ngIf="aptitudeReport && aptitudeReport.gameStatus"
      [ngTemplateOutlet]="aptitudeReportTemplate">
    </ng-container>
    <div class="ReportGrid page2" style="padding-top: 20px; padding-bottom: 20px">
      <div class="container-image" *ngIf="companyData.isProctoring">
        <div class="image-grid">
          <div *ngFor="let image of images">
            <img [src]="image.src" class="proctor-image" />
          </div>
        </div>
      </div>
      <div class="RTopLeftBox" style="height: auto"></div>
      <div class="RTopRightBox" style="height: auto"></div>
      <div class="DelegativeGrid">
        <div class="DelegativeBox">
          <h2 style="padding-top: 30px; text-align: center; margin-left: -20px">
            Disclaimer
          </h2>
          <p>
            This report is strictly confidential and contains the intellectual
            property of Stairwell Ventures. Stairwell Ventures permits the
            client to reproduce, distribute, amend and store this report for its
            internal and non-commercial use only. All other rights are reserved
            with Stairwell Ventures.
          </p>
          <p>
            While our research links psychometric and neurocognitive attributes
            measured by this assessment to important on-the-job behaviours,
            there are other factors that may impact on how well an individual
            performs at their job. These factors also need to be assessed (for
            eg. via an interview or any other selection and performance
            criteria) when appraising a candidate. A candidate's results may be
            influenced by external factors in the environment and his/her
            self-awareness, so the classification they achieved should be
            considered an indication or aproximation of the abilities measured
            by this assessment.
          </p>
          <p>
            Stairwell Ventures has made every effort to produce an informative
            report but, as with other psychometrics, it is not infallible. We
            cannot be held liable for actions taken from the interpretation of
            the information contained in this report. The report is likely to
            remain a good reflection of the individual’s potential for 12
            months, depending upon personal circumstances.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 3 point ends -->

<!-- 5 point -->
<div *ngIf="!isThreePointReport" style="background: #ccc">

  <div class="responsiveReportGrid" style="padding-bottom: 80px;">
    <div class="ReportGrid">
      <div class="page1" *ngIf="isPageOne">
        <div class="RTopLeft"></div>
        <div class="RTopRight"></div>
        <div class="ReportHead">
          <div class="RLogoGrid">
            <img src="assets/images/reports/Rlogo.svg" />
          </div>
          <!--  RLogoGrid  -->
          <!-- <div class="RDateGrid">
            <h2>
              <span>Date of Assessment</span> {{ viewReport?.report_data?.assessment_date }}</h2>
          </div> -->
          <!--  RDateGrid  -->
          <div class="clearfix"></div>
          <div class="RCandidateIm">
            <img *ngIf="getVerdictImage(viewReport, 'Unlikely to succeed')"
              src="assets/images/reports/clip_patch-02.png" style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Restricted likelihood of success')"
              src="assets/images/reports/clip_patch-04.png" style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Likely to succeed')" src="assets/images/reports/clip_patch-01.png"
              style="width: 200px; margin-top: 5px" />
            <img *ngIf="getVerdictImage(viewReport, 'Very Likely to succeed')"
              src="assets/images/reports/clip_patch-03.png" style="width: 200px; margin-top: 5px" />
          </div>
          <!-- RCandidateIm  -->
          <div class="RCandidateBox">
            <div class="RCandidateInfo">
              <h2>
                <span>Name of Candidate</span>{{ viewReport?.report_data?.candidate_first_name }}
                {{ viewReport?.report_data?.candidate_last_name }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Title</span>{{ viewReport?.report_data?.job_title }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
            <div class="RCandidateInfo">
              <h2>
                <span>Assessment Type</span>{{ getReportName(viewReport?.report_data?.job_type) }}
              </h2>
            </div>
            <!--  RCandidateInfo  -->
          </div>
          <!--  RCandidateBox  -->
          <div class="clearfix"></div>
          <div [ngClass]="{ 'reportheading-wrapper-div': true }">
            <!-- For All Job Types -->

            <div class="RBoxGrid" *ngIf="viewReport?.report_data?.title != '0NA'" [ngClass]="
                headingAlignment(
                  hideOrgComp,
                  viewReport?.report_data?.profile_name,
                  viewReport?.comp_framework
                )
              ">
              <h2 *ngIf="
                  viewReport?.report_data?.job_type !== 'Organisational Match'
                ">
                {{ viewReport?.report_data?.title }}
              </h2>
              <h2 *ngIf="
                  viewReport?.report_data?.job_type === 'Organisational Match'
                ">
                {{ viewReport?.report_data?.response.coverpage_name }}
              </h2>
              <p *ngIf="
                  viewReport?.report_data?.job_type !== 'Organisational Match'
                ">
                {{ viewReport?.report_data?.coverpage_subtext }}
              </p>
              <p *ngIf="
                  viewReport?.report_data?.job_type === 'Organisational Match'
                ">
                {{
                viewReport?.report_data?.response.orgcompetency_coverpage_text
                }}
              </p>
              <table class="ratingTable2">
                <tr class="ratingTR">
                  <td class="ratetd" style="width: 70%; text-align: bottom">
                    <div class="StarBox starCenter">
                      <div *ngIf="
                          viewReport?.report_data?.response
                            .overall_average_rating == 'Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Very Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.response
                            .overall_average_rating == 'Medium-Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.response
                            .overall_average_rating == 'Medium'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Medium</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.response
                            .overall_average_rating == 'Medium-High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">High</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.response
                            .overall_average_rating == 'High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star unchecked"></span>
                        <div class="clearfix">Very High</div>
                      </div>
                    </div>
                  </td>
                  <td class="rateText2 center">
                    {{ viewReport?.report_data?.response.overall_average_per }}
                  </td>
                </tr>
              </table>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Managerial Style in case of Customised Profile Fit -->
            <div class="RBoxGrid" [ngClass]="hideOrgComp ? 'floatLeft' : 'floatLeft'" *ngIf="
                viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
                viewReport?.report_data?.profile_name !=
                  'Individual Contributor'
              ">
              <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
              <p *ngIf="
                  viewReport?.report_data?.job_type == 'Customised Profile Fit'
                ">
                {{
                viewReport?.report_data?.mparam_response?.coverpage_subtext
                }}
              </p>
              <table class="ratingTable2">
                <tr class="ratingTR">
                  <td class="ratetd" style="width: 70%; text-align: bottom">
                    <div class="StarBox starCenter">
                      <div *ngIf="
                          viewReport?.report_data?.mparam_response
                            .overall_average_rating == 'Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Very Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.mparam_response
                            .overall_average_rating == 'Medium-Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.mparam_response
                            .overall_average_rating == 'Medium'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Medium</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.mparam_response
                            .overall_average_rating == 'Medium-High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">High</div>
                      </div>
                      <div *ngIf="
                          viewReport?.report_data?.mparam_response
                            .overall_average_rating == 'High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star unchecked"></span>
                        <div class="clearfix">Very High</div>
                      </div>
                    </div>
                  </td>
                  <td class="rateText2 center">
                    {{
                    viewReport?.report_data?.mparam_response
                    .overall_average_per
                    }}
                  </td>
                </tr>
              </table>
            </div>
            <!--  RBoxGrid  -->

            <!-- For Organizatinal competency in case of Customised Profile Fit -->
            <div class="RBoxGrid" *ngIf="hideOrgComp" [ngClass]="hideOrgComp ? 'floatLeft' : ''">
              <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
              <p [innerHTML]="getReplacedText(
                viewReport?.comp_framework?.orgcompetency_coverpage_text
                )">
                {{
                getReplacedText(
                viewReport?.comp_framework?.orgcompetency_coverpage_text
                )
                }}
              </p>
              <table class="ratingTable2">
                <tr class="ratingTR">
                  <td class="ratetd" style="width: 70%; text-align: bottom">
                    <div class="StarBox starCenter">
                      <div *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Very Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium-Low'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Low</div>
                      </div>
                      <div *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">Medium</div>
                      </div>
                      <div *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'Medium-High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star-o unchecked"></span>
                        <div class="clearfix">High</div>
                      </div>
                      <div *ngIf="
                          viewReport?.comp_framework?.overall_average_rating ==
                          'High'
                        ">
                        <span class="fa fa-star checked"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star"></span>
                        <span class="fa fa-star unchecked"></span>
                        <span class="fa fa-star unchecked"></span>
                        <div class="clearfix">Very High</div>
                      </div>
                    </div>
                  </td>
                  <td class="rateText2 center">
                    {{ viewReport?.comp_framework?.overall_average_per }}
                  </td>
                </tr>
              </table>
              <!-- <div class="MBRight" style="width:166px;">
                <div class="StarBox">
                  <div *ngIf="viewReport?.comp_framework?.overall_average_rating=='Low'">
                    <span class="BlackStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                  </div>
                  <div *ngIf="viewReport?.comp_framework?.overall_average_rating=='Medium-Low'">
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                  </div>
                  <div *ngIf="viewReport?.comp_framework?.overall_average_rating=='Medium'">
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="WhiteStar"></span>
                    <span class="WhiteStar"></span>
                  </div>
                  <div *ngIf="viewReport?.comp_framework?.overall_average_rating=='Medium-High'">
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="WhiteStar"></span>
                  </div>
                  <div *ngIf="viewReport?.report_data?.response.overall_average_rating=='High'">
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                    <span class="BlackStar"></span>
                  </div>
                  <div class="clearfix"></div>
                </div>

                <div class="MediumBox" >{{ viewReport?.report_data?.response.overall_average_per }}</div>
              </div> -->
              <!--  MBRight  -->
            </div>
            <!--  RBoxGrid  -->
              <!-- For Organizatinal competency in case of Customised Profile Fit -->
              <div class="RBoxGrid" *ngIf="hideOrgComp && isTvsCredit && viewReport?.report_data?.job_type == 'Campus Recruitment'" [ngClass]="hideOrgComp ? 'floatLeft' : ''">
                <h2>{{ brandReport?.comp_framework?.coverpage_name }}</h2>
                <p [innerHTML]="getReplacedText(
                 brandReport?.comp_framework?.orgcompetency_coverpage_text
                  )">
                  {{
                  getReplacedText(
                  brandReport?.comp_framework?.orgcompetency_coverpage_text
                  )
                  }}
                </p>
                <table class="ratingTable2">
                  <tr class="ratingTR">
                    <td class="ratetd" style="width: 70%; text-align: bottom">
                      <div class="StarBox starCenter">
                        <div *ngIf="
                            brandReport?.comp_framework?.overall_average_rating ==
                            'Low'
                          ">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star-o"></span>
                          <span class="fa fa-star-o"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <div class="clearfix">Very Low</div>
                        </div>
                        <div *ngIf="
                           brandReport?.comp_framework?.overall_average_rating ==
                            'Medium-Low'
                          ">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star-o"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <div class="clearfix">Low</div>
                        </div>
                        <div *ngIf="
                            brandReport?.comp_framework?.overall_average_rating ==
                            'Medium'
                          ">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <div class="clearfix">Medium</div>
                        </div>
                        <div *ngIf="
                            brandReport?.comp_framework?.overall_average_rating ==
                            'Medium-High'
                          ">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star unchecked"></span>
                          <span class="fa fa-star-o unchecked"></span>
                          <div class="clearfix">High</div>
                        </div>
                        <div *ngIf="
                            brandReport?.comp_framework?.overall_average_rating ==
                            'High'
                          ">
                          <span class="fa fa-star checked"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star"></span>
                          <span class="fa fa-star unchecked"></span>
                          <span class="fa fa-star unchecked"></span>
                          <div class="clearfix">Very High</div>
                        </div>
                      </div>
                    </td>
                    <td class="rateText2 center">
                      
                      {{ brandReport?.comp_framework?.overall_average_per }}
                    </td>
                  </tr>
                </table>
                <!--  MBRight  -->
              </div>
              <!--  RBoxGrid  -->


          </div>
          <div class="clearfix"></div>
          <div class="RBotBox">
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Unlikely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Unlikely to succeed')">This candidate is</span>-->
                Unlikely to succeed
              </div>
          <div class="full-width-output" style="width:fit-content">

                <output for="foo" class="black" *ngIf="getOverallVerdict(viewReport, 'Unlikely to succeed')"
                  onforminput="value = 200;" style="margin-left: 0px; width: 136px">Overall Rating : {{
                  getOverallRatingPer() }}</output>
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Restricted likelihood of success')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Restricted likelihood of success')">This candidate is</span> -->
                Restricted Likelihood of Success
              </div>
          <div class="full-width-output" style="width:fit-content">

                <output for="foo" class="black"
                  *ngIf="getOverallVerdict(viewReport, 'Restricted likelihood of success')" onforminput="value = 200;"
                  style="margin-left: 139px; width: 136px">Overall Rating : {{
                  getOverallRatingPer() }}</output>
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Likely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Likely to succeed') ">This candidate is</span> -->
                Likely to succeed
              </div>
          <div class="full-width-output" style="width:fit-content">

                <output for="foo" class="black" *ngIf="getOverallVerdict(viewReport, 'Likely to succeed')"
                  onforminput="value = 200;" style="margin-left: 278px; width: 136px">Overall Rating : {{
                  getOverallRatingPer() }}</output>
              </div>
            </a>
            <a href="javascript: void(0);">
              <div class="RTabBox" [ngClass]="getOverallVerdict(viewReport, 'Very Likely to succeed')">
                <!-- <span *ngIf="getOverallVerdict(viewReport,?.report_data?.response.overall_average, 'Very Likely to succeed')">This candidate is</span> -->
                Very likely to succeed
              </div>
          <div class="full-width-output" style="width:fit-content">

                <output for="foo" class="black" *ngIf="getOverallVerdict(viewReport, 'Very Likely to succeed')"
                  onforminput="value = 200;" style="margin-left: 417px; width: 136px">Overall Rating : {{
                  getOverallRatingPer() }}</output>
              </div>
            </a>
            <div class="clearfix"></div>
            <div class="report-legend-div">
              <!-- <div class="legend-bar">

                      </div> -->
              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">0</span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[0].maxValue}} </span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-0-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[1].maxValue}}</span>
                </div>
                <div class="legend-0-bar">
                </div>
              </div>

              <div class="legend-4-div">
                <div class="legend-0-box">
                  <span class="legend-text-font">{{companyData.customVerditBoundaries[2].maxValue}}</span>
                </div>
                <div class="legend-4-bar">
                </div>
                <div class="legend-4-box">
                  <span class="legend-text-font">100</span>
                </div>
              </div>
            </div>
          </div>
          <!--  RBotBox  -->
        </div>
        <!--  ReportHead  -->
      </div>
      
      <!-- page1 -->
    </div>
    <div class="clearfix"></div>
  </div>


  <div class="responsiveReportGrid" style="margin-top: -30px;padding-bottom: 10px;">
    <div class="ReportGrid" [ngClass]="isPageOne ? 'page2' : ''" style="padding:5px 0;">
      <div class="RTopLeftBox" *ngIf="viewReport?.report_data?.title != '0NA'"></div>
      <div class="RTopRightBox" *ngIf="viewReport?.report_data?.title != '0NA'"></div>
      <div class="clearfix" *ngIf="viewReport?.report_data?.title != '0NA'"></div>

      <div class="ManagerialBox" *ngIf="viewReport?.report_data?.title != '0NA'">
      
        <div class="MBLeft width100 report_data_not_org_match"
          *ngIf="viewReport?.report_data?.job_type !== 'Organisational Match'">
          <h2>{{ viewReport?.report_data?.title }}</h2>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating == 'Low'
            " [innerHTML]="getReplacedText(viewReport?.report_data?.section_subtext_low)">
            {{ getReplacedText(viewReport?.report_data?.section_subtext_low) }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium-Low'
            " [innerHTML]=" getReplacedText(
            viewReport?.report_data?.section_subtext_medium_low
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.section_subtext_medium_low
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium'
            " [innerHTML]="getReplacedText(
            viewReport?.report_data?.section_subtext_medium
            )">
            {{
            getReplacedText(viewReport?.report_data?.section_subtext_medium)
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium-High'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.section_subtext_high_medium
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.section_subtext_high_medium
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating == 'High'
            "  [innerHTML]="getReplacedText(
              viewReport?.report_data?.section_subtext_high
            )">
            {{ getReplacedText(viewReport?.report_data?.section_subtext_high) }}
          </p>
        </div>
        <!--  MBLeft  -->
        <div class="MBLeft width100 report_data_org_match"
          *ngIf="viewReport?.report_data?.job_type == 'Organisational Match'">
          <h2 *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'">
            {{ viewReport?.report_data?.title }}
          </h2>
          <h2 *ngIf="viewReport?.report_data?.job_type == 'Organisational Match'">
            {{ viewReport?.report_data?.response.coverpage_name }}
          </h2>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating == 'Low'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.response.section_subtext_low
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.response.section_subtext_low
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium-Low'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.response.section_subtext_medium_low
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.response.section_subtext_medium_low
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.response.section_subtext_medium
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.response.section_subtext_medium
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating ==
              'Medium-High'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.response.section_subtext_high_medium
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.response.section_subtext_high_medium
            )
            }}
          </p>
          <p *ngIf="
              viewReport?.report_data?.response.overall_average_rating == 'High'
            " [innerHTML]="getReplacedText(
              viewReport?.report_data?.response.section_subtext_high
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.response.section_subtext_high
            )
            }}
          </p>
        </div>

   
        <!--  MBLeft  -->
        <table class="FloatRightStar">
          <tr class="ratingTR">
            <td class="ratetd" style="width: 70%; text-align: bottom">
              <div class="StarBox starCenter floatrightFont">
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ==
                    'Low'
                  ">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Very Low</div>
                </div>
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ==
                    'Medium-Low'
                  ">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star-o"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Low</div>
                </div>
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ==
                    'Medium'
                  ">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">Medium</div>
                </div>
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ==
                    'Medium-High'
                  ">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star unchecked"></span>
                  <span class="fa fa-star-o unchecked"></span>
                  <div class="clearfix">High</div>
                </div>
                <div *ngIf="
                    viewReport?.report_data?.response.overall_average_rating ==
                    'High'
                  ">
                  <span class="fa fa-star checked"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star"></span>
                  <span class="fa fa-star unchecked"></span>
                  <span class="fa fa-star unchecked"></span>
                  <div class="clearfix">Very High</div>
                </div>
              </div>
            </td>
            <td class="rateText2 center">
              {{ viewReport?.report_data?.response.overall_average_per }}
            </td>
          </tr>
        </table>
        
      </div>
      <!--  ManagerialBox -->

      <div class="clearfix"></div>
      <div *ngIf="viewReport?.report_data?.job_type != 'Organisational Match'">
        <div class="CoreJob" *ngFor="
            let candidateActivity of viewReport?.report_data?.response?.response
              | keyvalue
          ">
          
          <!-- TODO -->
          <h2 *ngIf="viewReport?.report_data?.title != 'Managerial Ability'">
            {{ candidateActivity.key }}
          </h2>
          <div style="
              margin: 10px;
              padding: 18px;
              background-color: #f2f4f3;
              margin-bottom: 5px;
            " *ngFor="
              let activity of candidateActivity.value | orderBy: 'activity_name'
            ">
            <!-- | orderBy: 'activity_name' -->
            <table style="width: 100%; background-color: #f2f4f3; margin-top: -12px">
              <tr>
                <td class="ratetd verticleTop" style="width: 90%">
                  <span class="tdLeftText">{{ activity.activity_name }}</span>
                </td>
                <td>
                  <table class="ratingTable">
                    <tr class="ratingTR">
                      <td class="ratetd" style="width: 90%; text-align: bottom">
                        <div class="progress" style="">
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #f2936d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #fcbc83 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #ffd98d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #eaeb86 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #cce080 !important;
                            "></div>
                        </div>
                    <div class="full-width-output" style="width:fit-content">

                          <output for="foo" *ngIf="activity.rating == 'Low'" class="vlow"
                            onforminput="value = 200;" style="min-width: 53px;">Very Low</output>
                          <output for="foo" *ngIf="activity.rating == 'Medium-Low'" class="low"
                            onforminput="value = 200;">Low</output>
                          <output for="foo" *ngIf="activity.rating == 'Medium'" class="medium"
                            onforminput="value = 200;">Medium</output>
                          <output for="foo" *ngIf="activity.rating == 'Medium-High'" class="high"
                            onforminput="value = 200;">High</output>
                          <output for="foo" *ngIf="activity.rating == 'High'" class="vhigh" style="min-width: 53px;"
                            onforminput="value = 200;">Very High</output>
                        </div>
                      </td>
                      <td class="rateText ratetd center">
                        {{ activity.average_per }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <hr />
            <div class="MBLeft">
              <p class="ratingP" *ngIf="activity.ratingtext" [innerHTML]="getReplacedText(activity.ratingtext)">
                {{ getReplacedText(activity.ratingtext) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--  CoreJob  -->

      <!-- Job Type == Organsational Match -->
      <div *ngIf="viewReport?.report_data?.response?.org_data">
        <div class="ReportGrid" *ngFor="
            let orgdetail of viewReport?.report_data?.response?.org_data
              | orderBy: 'org_name';
            let i = index
          ">
          <div class="PeopleOriGrid" [ngClass]="i == 0 ? 'marginTop60' : 'marginTop20'">
            <table>
              <tr>
                <td class="ratetd" style="width: 62%">
                  <h3 class="rateH3">{{ orgdetail.org_name }}</h3>
                  <hr style="width: 75% !important; margin-left: 0 !important" />
                </td>
                <td>
                  <table class="ratingTable">
                    <tr class="ratingTR">
                      <td class="ratetd verticleTop" style="width: 90%; text-align: bottom">
                        <div class="progress" style="">
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #f2936d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #fcbc83 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #ffd98d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #eaeb86 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #cce080 !important;
                            "></div>
                        </div>
                    <div class="full-width-output" style="width:fit-content">

                          <output for="foo" *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Low'
                            " class="vlow" onforminput="value = 200;" style="min-width: 53px;">Very Low</output>
                          <output for="foo" *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium-Low'
                            " class="low" onforminput="value = 200;">Low</output>
                          <output for="foo" *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium'
                            " class="medium" onforminput="value = 200;">Medium</output>
                          <output for="foo" *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'Medium-High'
                            " class="high" onforminput="value = 200;">High</output>
                          <output for="foo" *ngIf="
                              orgdetail.org_competency.overall_average_rating ==
                              'High'
                            " class="vhigh" onforminput="value = 200;" style="min-width: 53px;">Very High</output>
                        </div>
                      </td>
                      <td class="rateText center ratetd ng-repeat-orgdetail">
                        {{ orgdetail.org_competency.overall_average_per }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <div class="MBLeft">
              <p class="ratingP orgRating" *ngIf="orgdetail.rating_text" [innerHTML]="getReplacedText(orgdetail.rating_text)">
                {{ getReplacedText(orgdetail.rating_text) }}
              </p>
              <p class="ratingP orgrating" *ngIf="orgdetail.ratingtext" [innerHTML]="getReplacedText(orgdetail.ratingtext)">
                {{ getReplacedText(orgdetail.ratingtext) }}
              </p>
            </div>
            <div *ngFor="
                let subcompetency of orgdetail.org_competency.response
                  | orderBy: 'activity_name'
              ">
              <div style="
                  margin: 10px;
                  padding: 18px;
                  background-color: #f2f4f3;
                  margin-bottom: 5px;
                ">
                <table style="
                    width: 100%;
                    background-color: #f2f4f3;
                    margin-top: -12px;
                  ">
                  <tr>
                    <td class="ratetd verticleTop" style="width: 90%">
                      <span class="tdLeftText">{{
                        subcompetency.activity_name
                        }}</span>
                    </td>
                    <td>
                      <table class="ratingTable">
                        <tr class="ratingTR">
                          <td class="ratetd verticleTop" style="width: 90%; text-align: bottom">
                            <div class="progress" style="">
                              <div class="progress-bar" role="progressbar" style="
                                  width: 20%;
                                  background-color: #f2936d !important;
                                "></div>
                              <div class="progress-bar" role="progressbar" style="
                                  width: 20%;
                                  background-color: #fcbc83 !important;
                                "></div>
                              <div class="progress-bar" role="progressbar" style="
                                  width: 20%;
                                  background-color: #ffd98d !important;
                                "></div>
                              <div class="progress-bar" role="progressbar" style="
                                  width: 20%;
                                  background-color: #eaeb86 !important;
                                "></div>
                              <div class="progress-bar" role="progressbar" style="
                                  width: 20%;
                                  background-color: #cce080 !important;
                                "></div>
                            </div>
                        <div class="full-width-output" style="width:fit-content">

                              <output for="foo" *ngIf="subcompetency.rating == 'Low'" class="vlow"
                                onforminput="value = 100;" style="min-width: 53px;">Very Low</output>
                              <output for="foo" *ngIf="subcompetency.rating == 'Medium-Low'" class="low"
                                onforminput="value = 100;">Low</output>
                              <output for="foo" *ngIf="subcompetency.rating == 'Medium'" class="medium"
                                onforminput="value = 100;">Medium</output>
                              <output for="foo" *ngIf="subcompetency.rating == 'Medium-High'" class="high"
                                onforminput="value = 100;">High</output>
                              <output for="foo" *ngIf="subcompetency.rating == 'High'" class="vhigh"
                                style="margin-left: 80px" onforminput="value = 100;" style="min-width: 53px;">Very High</output>
                            </div>
                          </td>
                          <td class="rateText center ratetd subcompetencyPer">
                            {{ subcompetency.average_per }}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <hr />
                <div class="MBLeft">
                  <p class="ratingP ng-for-subcompetency" *ngIf="subcompetency.ratingtext" [innerHTML]="getReplacedText(subcompetency.ratingtext)">
                    {{ getReplacedText(subcompetency.ratingtext) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Organsational Match  -->

      <!-- Managerial Ability in case of Customised Job Fit -->
      <div class="ReportGrid page2" *ngIf="
          viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
          viewReport?.report_data?.profile_name != 'Individual Contributor'
        ">
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox" style="height: auto">
          <div class="MBLeft width100">
            <h2>{{ viewReport?.report_data?.mparam_response.title }}</h2>
            <p *ngIf="
                viewReport?.report_data?.mparam_response
                  .overall_average_rating == 'Low'
              ">
              {{ viewReport?.report_data?.mparam_response.section_subtext_low }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.mparam_response
                  .overall_average_rating == 'Medium-Low'
              ">
              {{
              viewReport?.report_data?.mparam_response
              .section_subtext_medium_low
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.mparam_response
                  .overall_average_rating == 'Medium'
              ">
              {{
              viewReport?.report_data?.mparam_response.section_subtext_medium
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.mparam_response
                  .overall_average_rating == 'Medium-High'
              ">
              {{
              viewReport?.report_data?.mparam_response
              .section_subtext_high_medium
              }}
            </p>
            <p *ngIf="
                viewReport?.report_data?.mparam_response
                  .overall_average_rating == 'High'
              ">
              {{
              viewReport?.report_data?.mparam_response.section_subtext_high
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <table class="FloatRightStar">
            <tr class="ratingTR">
              <td class="ratetd" style="width: 70%; text-align: bottom">
                <div class="StarBox starCenter floatrightFont">
                  <div *ngIf="
                      viewReport?.report_data?.mparam_response
                        .overall_average_rating == 'Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Very Low</div>
                  </div>
                  <div *ngIf="
                      viewReport?.report_data?.mparam_response
                        .overall_average_rating == 'Medium-Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Low</div>
                  </div>
                  <div *ngIf="
                      viewReport?.report_data?.mparam_response
                        .overall_average_rating == 'Medium'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Medium</div>
                  </div>
                  <div *ngIf="
                      viewReport?.report_data?.mparam_response
                        .overall_average_rating == 'Medium-High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">High</div>
                  </div>
                  <div *ngIf="
                      viewReport?.report_data?.mparam_response
                        .overall_average_rating == 'High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star unchecked"></span>
                    <div class="clearfix">Very High</div>
                  </div>
                </div>
              </td>
              <td class="rateText2 center">
                {{
                viewReport?.report_data?.mparam_response.overall_average_per
                }}
              </td>
            </tr>
          </table>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>
        <div class="CoreJob" style="margin-top: 60px">
          <div style="
              margin: 10px;
              padding: 18px;
              background-color: #f2f4f3;
              margin-bottom: 25px;
              margin-top: -18px;
            " *ngFor="
              let activityComp of viewReport?.report_data?.mparam_response
                .response | orderBy: 'activity_name'
            ">
            <table style="width: 100%; background-color: #f2f4f3; margin-top: -12px">
              <tr>
                <td class="ratetd verticleTop" style="width: 90%">
                  <span class="tdLeftText">{{
                    activityComp.activity_name
                    }}</span>
                </td>
                <td>
                  <table class="ratingTable">
                    <tr class="ratingTR">
                      <td class="ratetd" style="width: 90%; text-align: bottom">
                        <div class="progress" style="">
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #f2936d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #fcbc83 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #ffd98d !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #eaeb86 !important;
                            "></div>
                          <div class="progress-bar" role="progressbar" style="
                              width: 20%;
                              background-color: #cce080 !important;
                            "></div>
                        </div>
                    <div class="full-width-output" style="width:fit-content">

                          <output for="foo" *ngIf="activityComp.rating == 'Low'" class="vlow"
                            onforminput="value = 200;" style="min-width: 53px;">Very Low</output>
                          <output for="foo" *ngIf="activityComp.rating == 'Medium-Low'" class="low"
                            onforminput="value = 200;">Low</output>
                          <output for="foo" *ngIf="activityComp.rating == 'Medium'" class="medium"
                            onforminput="value = 200;">Medium</output>
                          <output for="foo" *ngIf="activityComp.rating == 'Medium-High'" class="high"
                            onforminput="value = 200;">High</output>
                          <output for="foo" *ngIf="activityComp.rating == 'High'" class="vhigh" style="min-width: 53px;"
                            onforminput="value = 200;">Very High</output>
                        </div>
                      </td>
                      <td class="rateText center ratetd">
                        {{ activityComp.average_per }}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <hr />
            <div class="MBLeft">
              <p class="ratingP" *ngIf="activityComp.ratingtext" [innerHTML]="getReplacedText(activityComp.ratingtext)">
                {{ getReplacedText(activityComp.ratingtext) }}
              </p>
            </div>
          </div>
        </div>
        <!--  CoreJob  -->
      </div>

      <!-- Managerial Style in case of Customised Job Fit -->
      <div class="DelegativeGrid" *ngIf="
          viewReport?.report_data?.job_type == 'Customised Profile Fit' &&
          viewReport?.report_data?.profile_name != 'Individual Contributor'
        " style="padding-top: 18px">
        <h2>
          {{
          viewReport?.report_data?.working_style['Managerial Style']
          .main_heading
          }}
        </h2>
        <div class="DelegativeBox">
          <p [innerHTML]="getReplacedText(
            viewReport?.report_data?.working_style['Managerial Style']
            .main_sub_text
            )">
            {{
            getReplacedText(
            viewReport?.report_data?.working_style['Managerial Style']
            .main_sub_text
            )
            }}
          </p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Managerial Style'][
                  'Leadership (democratic)'
                ]?.sub_heading_description
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Managerial Style'][
                  'Leadership (transformational)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Managerial Style'][
                  'Leadership (Laissez Faire)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Managerial Style'][
                  'Leadership (autocratic)'
                ]?.sub_heading_description
                
              )
            "></p>
        </div>
      </div>

      <!-- Organizatonl competencies in case of job fit and campus Recruitment-->
      <div class="ReportGrid page2" *ngIf="hideOrgComp">
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox" style="height: auto">
          <div class="MBLeft width100">
            <h2>{{ viewReport?.comp_framework?.coverpage_name }}</h2>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'Low'
              "  [innerHTML]="getReplacedText(viewReport?.comp_framework?.section_subtext_low)">
              {{
              getReplacedText(viewReport?.comp_framework?.section_subtext_low)
              }}
            </p>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating ==
                'Medium-Low'
              " [innerHTML]="getReplacedText(
              viewReport?.comp_framework?.section_subtext_medium_low
              )">
              {{
              getReplacedText(
              viewReport?.comp_framework?.section_subtext_medium_low
              )
              }}
            </p>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'Medium'
              " [innerHTML]="getReplacedText(viewReport?.comp_framework?.section_subtext_medium)">
              {{
              getReplacedText(
              viewReport?.comp_framework?.section_subtext_medium
              )
              }}
            </p>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating ==
                'Medium-High'
              " [innerHTML]="  getReplacedText(viewReport?.comp_framework?.section_subtext_medium)">
              {{
              getReplacedText(
              viewReport?.comp_framework?.section_subtext_high_medium
              )
              }}
            </p>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'High'
              " [innerHTML]="  getReplacedText(viewReport?.comp_framework?.section_subtext_high)">
              {{
              getReplacedText(
              viewReport?.comp_framework?.section_subtext_high
              )
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <table class="FloatRightStar">
            <tr class="ratingTR">
              <td class="ratetd" style="width: 70%; text-align: bottom">
                <div class="StarBox starCenter floatrightFont">
                  <div *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Very Low</div>
                  </div>
                  <div *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Low</div>
                  </div>
                  <div *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Medium</div>
                  </div>
                  <div *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'Medium-High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">High</div>
                  </div>
                  <div *ngIf="
                      viewReport?.comp_framework?.overall_average_rating ==
                      'High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star unchecked"></span>
                    <div class="clearfix">Very High</div>
                  </div>
                </div>
              </td>
              <td class="rateText2 center">
                {{ viewReport?.comp_framework?.overall_average_per }}
              </td>
            </tr>
          </table>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>

        <div class="CoreJob" style="margin-top: 60px">
          <!-- <h2> {{ candidateActivity.org_name }} </h2> -->
          <div *ngIf="viewReport?.comp_framework?.org_data">
            <div *ngFor="
                let activityComp of viewReport?.comp_framework?.org_data
                  | orderBy: 'org_name'
              " style="
                margin: 10px;
                padding: 18px;
                background-color: #f2f4f3;
                margin-bottom: 5px;
              ">
              <table style="
                  width: 100%;
                  background-color: #f2f4f3;
                  margin-top: -12px;
                ">
                <tr>
                  <td class="ratetd verticleTop" style="width: 90%">
                    <span class="tdLeftText">{{ activityComp.org_name }}</span>
                  </td>
                  <td>
                    <table class="ratingTable">
                      <tr class="ratingTR">
                        <td class="ratetd" style="width: 90%; text-align: bottom">
                          <div class="progress" style="">
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #f2936d !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #fcbc83 !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #ffd98d !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #eaeb86 !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #cce080 !important;
                              "></div>
                          </div>
                      <div class="full-width-output" style="width:fit-content">

                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Low'
                              " class="vlow" onforminput="value = 200;" style="min-width: 53px;">Very Low</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-Low'
                              " class="low" onforminput="value = 200;">Low</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium'
                              " class="medium" onforminput="value = 200;">Medium</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-High'
                              " class="high" onforminput="value = 200;">High</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'High'
                              " class="vhigh" onforminput="value = 200;" style="min-width: 53px;">Very High</output>
                          </div>
                        </td>
                        <td class="rateText center ratetd">
                          {{ activityComp.org_competency.overall_average_per }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="MBLeft">
                <p class="ratingP" *ngIf="activityComp.ratingtext" [innerHTML]="getReplacedText(activityComp.ratingtext)">
                  {{ getReplacedText(activityComp.ratingtext) }}
                </p>
                <p class="ratingP" *ngIf="activityComp.rating_text" [innerHTML]="getReplacedText(activityComp.rating_text)">
                  {{ getReplacedText(activityComp.rating_text) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--  CoreJob  -->
      </div>


      <div class="ReportGrid page2" *ngIf="hideOrgComp && isTvsCredit && viewReport?.report_data?.job_type == 'Campus Recruitment'">
        <div class="RTopLeftBox"></div>
        <div class="RTopRightBox"></div>
        <div class="clearfix"></div>
        <div class="ManagerialBox" style="height: auto">
          <div class="MBLeft width100">
            <h2>{{ brandReport?.comp_framework?.coverpage_name }}</h2>
            <p *ngIf="
                viewReport?.comp_framework?.overall_average_rating == 'Low'
              "  [innerHTML]="getReplacedText(brandReport?.comp_framework?.section_subtext_low)">
              {{
              getReplacedText(brandReport?.comp_framework?.section_subtext_low)
              }}
            </p>
            <p *ngIf="
                brandReport?.comp_framework?.overall_average_rating ==
                'Medium-Low'
              " [innerHTML]="getReplacedText(
              brandReport?.comp_framework?.section_subtext_medium_low
              )">
              {{
              getReplacedText(
              brandReport?.comp_framework?.section_subtext_medium_low
              )
              }}
            </p>
            <p *ngIf="
                brandReport?.comp_framework?.overall_average_rating == 'Medium'
              " [innerHTML]="getReplacedText(brandReport?.comp_framework?.section_subtext_medium)">
              {{
              getReplacedText(
              brandReport?.comp_framework?.section_subtext_medium
              )
              }}
            </p>
            <p *ngIf="
                brandReport?.comp_framework?.overall_average_rating ==
                'Medium-High'
              " [innerHTML]="  getReplacedText(brandReport?.comp_framework?.section_subtext_medium)">
              {{
              getReplacedText(
              brandReport?.comp_framework?.section_subtext_high_medium
              )
              }}
            </p>
            <p *ngIf="
                brandReport?.comp_framework?.overall_average_rating == 'High'
              " [innerHTML]="  getReplacedText(brandReport?.comp_framework?.section_subtext_high)">
              {{
              getReplacedText(
              brandReport?.comp_framework?.section_subtext_high
              )
              }}
            </p>
          </div>
          <!--  MBLeft  -->
          <table class="FloatRightStar">
            <tr class="ratingTR">
              <td class="ratetd" style="width: 70%; text-align: bottom">
                <div class="StarBox starCenter floatrightFont">
                  <div *ngIf="
                      brandReport?.comp_framework?.overall_average_rating ==
                      'Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Very Low</div>
                  </div>
                  <div *ngIf="
                      brandReport?.comp_framework?.overall_average_rating ==
                      'Medium-Low'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Low</div>
                  </div>
                  <div *ngIf="
                      brandReport?.comp_framework?.overall_average_rating ==
                      'Medium'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">Medium</div>
                  </div>
                  <div *ngIf="
                      brandReport?.comp_framework?.overall_average_rating ==
                      'Medium-High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star-o unchecked"></span>
                    <div class="clearfix">High</div>
                  </div>
                  <div *ngIf="
                      brandReport?.comp_framework?.overall_average_rating ==
                      'High'
                    ">
                    <span class="fa fa-star checked"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star"></span>
                    <span class="fa fa-star unchecked"></span>
                    <span class="fa fa-star unchecked"></span>
                    <div class="clearfix">Very High</div>
                  </div>
                </div>
              </td>
              <td class="rateText2 center">
                {{ brandReport?.comp_framework?.overall_average_per }}
              </td>
            </tr>
          </table>
        </div>
        <!--  ManagerialBox -->
        <div class="clearfix"></div>

        <div class="CoreJob" style="margin-top: 60px">
          <!-- <h2> {{ candidateActivity.org_name }} </h2> -->
          <div *ngIf="brandReport?.comp_framework?.org_data">
            <div *ngFor="
                let activityComp of brandReport?.comp_framework?.org_data
                  | orderBy: 'org_name'
              " style="
                margin: 10px;
                padding: 18px;
                background-color: #f2f4f3;
                margin-bottom: 5px;
              ">
              <table style="
                  width: 100%;
                  background-color: #f2f4f3;
                  margin-top: -12px;
                ">
                <tr>
                  <td class="ratetd verticleTop" style="width: 90%">
                    <span class="tdLeftText">{{ activityComp.org_name }}</span>
                  </td>
                  <td>
                    <table class="ratingTable">
                      <tr class="ratingTR">
                        <td class="ratetd" style="width: 90%; text-align: bottom">
                          <div class="progress" style="">
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #f2936d !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #fcbc83 !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #ffd98d !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #eaeb86 !important;
                              "></div>
                            <div class="progress-bar" role="progressbar" style="
                                width: 20%;
                                background-color: #cce080 !important;
                              "></div>
                          </div>
                      <div class="full-width-output" style="width:fit-content">

                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Low'
                              " class="vlow" onforminput="value = 200;" style="min-width: 53px;">Very Low</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-Low'
                              " class="low" onforminput="value = 200;">Low</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium'
                              " class="medium" onforminput="value = 200;">Medium</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'Medium-High'
                              " class="high" onforminput="value = 200;">High</output>
                            <output for="foo" *ngIf="
                                activityComp.org_competency
                                  .overall_average_rating == 'High'
                              " class="vhigh" onforminput="value = 200;" style="min-width: 53px;">Very High</output>
                          </div>
                        </td>
                        <td class="rateText center ratetd">
                          {{ activityComp.org_competency.overall_average_per }}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
              <hr />
              <div class="MBLeft">
                <p class="ratingP" *ngIf="activityComp.ratingtext" [innerHTML]="getReplacedText(activityComp.ratingtext)">
                  {{ getReplacedText(activityComp.ratingtext) }}
                </p>
                <p class="ratingP" *ngIf="activityComp.rating_text" [innerHTML]="getReplacedText(activityComp.rating_text)">
                  {{ getReplacedText(activityComp.rating_text) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!--  CoreJob  -->
      </div>


    </div>
  </div>


  <div class="responsiveReportGrid" >
    <!-- Working Style Section to starts here -->
    <div class="ReportGrid" style="padding:5px 0;"
      *ngIf="viewReport?.report_data?.job_type == 'Customised Profile Fit'">
      <div class="RTopLeftBox"></div>
      <div class="RTopRightBox"></div>
      <div class="clearfix"></div>
      <div class="ManagerialBox">
        <h2 class="ManagHedding">
          {{ viewReport?.report_data?.working_style.name }}
        </h2>
        <p class="ManagInfo">
          {{ viewReport?.report_data?.working_style.cover_page_text }}
        </p>
      </div>
      <!--  ManagerialBox -->
      <div class="clearfix"></div>
      <div class="DelegativeGrid" [ngClass]="
          viewReport?.report_data?.job_type != 'Organisational Match'
            ? 'marginTop60'
            : ''
        ">
        <ng-container *ngIf="viewReport?.report_data?.working_style['Key Driver']">

          <h2>
            {{
            viewReport?.report_data?.working_style['Key Driver'].main_heading
            }}
          </h2>
          <div class="DelegativeBox" style="margin-top: -8px">
            <p>
              {{
              getReplacedText(
              viewReport?.report_data?.working_style['Key Driver']
              .main_sub_text
              )
              }}
            </p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (social)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (self growth)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (novelty)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (prestige)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (freedom)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (aesthetic)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (goal-oriented)'
                ]?.sub_heading_description
                
              )
            "></p>
            <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Key Driver'][
                  'Motivation (reward)'
                ]?.sub_heading_description
                
              )
            "></p>
          </div>
        </ng-container>
        <!-- DelegativeBox  -->
        <p></p>
        <p></p>
        <h2 style="padding-top: 20px">
          {{
          viewReport?.report_data?.working_style['Influencing Style']
          .main_heading
          }}
        </h2>
        <div class="DelegativeBox" style="margin-top: -8px">
          <p>
            {{
            getReplacedText(
            viewReport?.report_data?.working_style['Influencing Style']
            .main_sub_text
            )
            }}
          </p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (EPL)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (EP)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (PL)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (EL)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (E)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (P)'
                ]?.sub_heading_description
                
              )
            "></p>
          <p [innerHTML]="
              safeHtml(
                viewReport?.report_data?.working_style['Influencing Style'][
                  'Influencing Style (L)'
                ]?.sub_heading_description
                
              )
            "></p>
        </div>
        <!-- DelegativeBox  -->
      </div>
      <!--  DelegativeGrid  -->
    </div>
  </div>




  <ng-container [ngTemplateOutlet]="technicalAssessmentUI">
  </ng-container>



<div class="responsiveReportGrid">

    <!-- Working Style Section to end here -->
    <ng-container *ngIf="aptitudeReport" [ngTemplateOutlet]="aptitudeReportTemplate">
    </ng-container>
</div>

  <div class="responsiveReportGrid" style="margin-top: -30px;">
    <div class="ReportGrid page2" style="padding: 5px 0;">
      <div class="container-image" *ngIf="companyData.isProctoring">
        <div class="image-grid">
          <div *ngFor="let image of images">
            <img [src]="image.src" class="proctor-image" />
          </div>
        </div>
      </div>
      <div class="RTopLeftBox" style="height: auto"></div>
      <div class="RTopRightBox" style="height: auto"></div>
      <div class="DelegativeGrid">
        <div class="DelegativeBox">
          <h2 style="padding-top: 30px; text-align: center; margin-left: -20px">
            Disclaimer
          </h2>
          <p>
            This report is strictly confidential and contains the intellectual
            property of Stairwell Ventures. Stairwell Ventures permits the
            client to reproduce, distribute, amend and store this report for its
            internal and non-commercial use only. All other rights are reserved
            with Stairwell Ventures.
          </p>
          <p>
            While our research links psychometric and neurocognitive attributes
            measured by this assessment to important on-the-job behaviours,
            there are other factors that may impact on how well an individual
            performs at their job. These factors also need to be assessed (for
            eg. via an interview or any other selection and performance
            criteria) when appraising a candidate. A candidate's results may be
            influenced by external factors in the environment and his/her
            self-awareness, so the classification they achieved should be
            considered an indication or aproximation of the abilities measured
            by this assessment.
          </p>
          <p>
            Stairwell Ventures has made every effort to produce an informative
            report but, as with other psychometrics, it is not infallible. We
            cannot be held liable for actions taken from the interpretation of
            the information contained in this report. The report is likely to
            remain a good reflection of the individual’s potential for 12
            months, depending upon personal circumstances.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- 5 point ends -->


<ng-template #technicalAssessmentUI>
  <div
  *ngIf="isThermax && viewReport?.report_data?.job_type == 'Campus Recruitment' && 
    technicalAssessment &&
    technicalAssessment.assessmentId &&
    technicalAssessment.assessmentId.department &&
    technicalAssessment.assessmentId.department.benchmarks &&
    technicalAssessment.assessmentId.department.benchmarks.length > 0
  ">


<div class="responsiveReportGrid">
<div  class="ReportGrid">
  <div
  style="margin-top: 20px;"
  *ngIf="companyData.mcqCount > 0 && assessmentDetail.department"
  >
  <img
  
    src="assets/images/thermax-guide-1.jpg"
    style="width: 100%;"
  />
 
  </div>
</div>
<div class="clearfix"></div>
</div>

<div class="responsiveReportGrid">
  <div  class="ReportGrid">
    <div
    style="margin-top: 20px;"
    *ngIf="companyData.mcqCount > 0 && assessmentDetail.department"
    >
    <img
    
      src="assets/images/thermax-guide-2.jpg"
      style="width: 100%;"
    />
    </div>
  </div>
  <div class="clearfix"></div>
  </div>

<div class="responsiveReportGrid" style="margin-top: 20px; padding: 5px 0;">
  <div class="ReportGrid">
    <div style="">

      <div class="RTopLeftBox" style="border-bottom: 0;background: white;height: 40px;"></div>
      <div class="RTopRightBox" style="border-bottom: 0;background: white;height: 40px;"></div>
      <div class="clearfix"></div>
      <div class="ManagerialBox" style="width: 580px;margin-left: 8px;padding:6px;position: relative;margin-top: 0;">
       <div style="background: #f5f6f8;text-align: center;padding:20px;">
        <h4 style="margin:0;">
          TECHNICAL ASSESSMENT REPORT
        </h4>
        <h5 style="margin-top: 15px;">
          <span style="color:#ea6764">Domain : </span>
    {{technicalAssessment.assessmentId.department.reportTitle}}
        </h5>
       </div>
    
    
       <div class="center">
        <h5 style="margin-top: 15px;"> Topics assessed:</h5>
        <p>{{  technicalAssessment.assessmentId.department.topicsAssessed}}</p>
       </div>
    
    
      </div>
      <!--  ManagerialBox -->
      <div class="clearfix"></div>
      <div class="DelegativeGrid">
        <div class="pie-chart total-pie-chart" style="margin-top: 3.5rem;">
          <div class="pie-chart--image-container" [ngStyle]="{
              'background-image': getFullCircleImage(
                technicalAssessmentRating
     * 10
              )
            }" style="width: 13em;height: 13em;">
            <span class="pie-chart--score" style="font-size: 1.3em;">{{
             technicalAssessmentRating
         
              }}/10</span>
          </div>
          <h6 style="color:#929292;">Proficiency</h6>
    
          <div class="tech-assessment-rating-container" [ngClass]="this.mcqResultClassArray[this.technicalAssessmentRating]">
            {{this.mcqResultArray[this.technicalAssessmentRating]}}
          </div>
        </div>
        <!-- DelegativeBox  -->
        <p></p>
        <p></p>
    
        <!-- DelegativeBox  -->
      </div>
    
    </div>
  </div>
</div>
  <!--  DelegativeGrid  -->
</div>
</ng-template>


<ng-template #aptitudeReportTemplate>
  <div class="ReportGrid">
    <div class="RTopLeftBox"></div>
    <div class="RTopRightBox"></div>
    <div class="clearfix"></div>
    <div class="ManagerialBox center-flex">
      <h2 class="ManagHedding">Aptitude Report</h2>
      <!-- <<<<<<< HEAD=======>>>>>>> 4ed185ab7df04a3f988b6bf108c6d4780706bbab -->
    </div>
    <div class="report-body" style="background: #ccc">
      <div class="report-container">
        <div class="DelegativeBox marginTop60">
          <section class="pie-charts-section">
            <div class="pie-charts-container" [ngStyle]="{
                'justify-content':
                  aptitudeReport.sectionCount < 3
                    ? 'space-around'
                    : 'space-between'
              }">
              <div class="pie-chart" *ngIf="aptitudeReport.sectionScoresTotal.aptitude">
                <div class="pie-chart--title">Cognitive Abilities</div>
                <div class="pie-chart--image-container" [ngStyle]="{
                    'background-image': getFullCircleImage(
                      aptitudeReport.sectionDetailTotalScore.aptitude * 10
                    )
                  }">
                  <span class="pie-chart--score">
                    {{
                    aptitudeReport.sectionDetailTotalScore.aptitude
                    | number: '1.0-1'
                    }}/10</span>
                </div>
              </div>
              <div class="pie-chart" *ngIf="aptitudeReport.sectionScoresTotal.quant">
                <div class="pie-chart--title">Numerical Reasoning</div>
                <div class="pie-chart--image-container" [ngStyle]="{
                    'background-image': getFullCircleImage(
                      aptitudeReport.sectionDetailTotalScore.quant * 10
                    ),
                    'height': isTvsCredit ? '38em' : '',
                    'width': isTvsCredit ? '38em' : ''
                  }">
                  <span class="pie-chart--score">{{
                 isTvsCredit ? round(aptitudeReport.sectionDetailTotalScore.quant * 10) : (aptitudeReport.sectionDetailTotalScore.quant | number: '1.0-1')
                    }}/10{{isTvsCredit ? '0':''}}</span>
                </div>
              </div>
              <div class="pie-chart" *ngIf="aptitudeReport.sectionScoresTotal.language">
                <div class="pie-chart--title">Verbal Reasoning</div>
                <div class="pie-chart--image-container" [ngStyle]="{
                    'background-image': getFullCircleImage(
                      aptitudeReport.sectionDetailTotalScore.language * 10
                    ),
                    'height': isTvsCredit ? '38em' : '',
                    'width': isTvsCredit ? '38em' : ''
                  }">
                  <span class="pie-chart--score">{{
               isTvsCredit ? round(aptitudeReport.sectionDetailTotalScore.language * 10) : aptitudeReport.sectionDetailTotalScore.language | number: '1.0-1'
                    }}/10{{isTvsCredit ? '0':''}}</span>
                </div>
              </div>
            </div>
          </section>

          <section class="score-bar-section">
            <div class="score-bar-container">
              <h2 class="score-summary--title HD-title">Aptitude Score</h2>
              <div class="scorebar-image">
                <img src="../../../assets/images/apti-report/scorebar.png" alt="Scorebar image" />
                <div class="progress-circle-container">
                  <div class="progress-circle" [ngStyle]="{ left: aptitudeReport.totalScore * 10 + '%' }">
                    <span>{{
                    isTvsCredit ? round(aptitudeReport.totalScore * 10) : aptitudeReport.totalScore | number: '1.0-1'
                      }}/10{{isTvsCredit ? '0':''}}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="DelegativeBox" style="padding-left: 10px; padding-right: 10px">
          <section class="detailedScore-section">
            <div class="detailedScore-container" [ngStyle]="{
                'justify-content':
                  aptitudeReport.sectionCount < 3
                    ? 'space-around'
                    : 'space-between'
              }">
              <!-- Aptitude Section -->
              <div class="detailedScore-Card" *ngIf="aptitudeReport.sectionDetailedScores.aptitude.length > 0">
                <div class="detailedScore-Card-title">
                  <span class="HD-Title"> Cognitive Abilities </span>
                </div>
                <div class="score-meters">
                  <div class="score-meter-container" *ngFor="
                      let sectionData of aptitudeReport.sectionDetailedScores
                        .aptitude
                    ">
                    <h4 class="score-meter-title">
                      {{
                      gameNameToAttributes[
                      getOgGameName(sectionData.gameName)
                      ]
                      }}
                    </h4>
                    <div class="score-meter-text" [innerHTML]="
                        reportContent[getOgGameName(sectionData.gameName)]
                      "></div>
                    <img class="scoreMeter-image" [src]="
                        urlHalfCircleImg +
                        (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                        '.png'
                      " alt="rating meter" />
                    <div class="scoreMeter-score">
                      <span> {{ sectionData.rating }}/10 </span>
                    </div>
                  </div>
                  <!-- <div class="score-meter-container">
                    <h4 class="score-meter-title">Planning and Prioritization</h4>
                    <div class="score-meter-text">
                      ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                      scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                      Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                    </div>
                    <img
                      class="scoreMeter-image"
                      src="../../../assets/images/apti-report/score3.png"
                      alt=""
                    />
                    <div class="scoreMeter-score">
                      <span> 4/10 </span>
                    </div>
                  </div>
                  <div class="score-meter-container">
                    <h4 class="score-meter-title">Planning and Prioritization</h4>
                    <div class="score-meter-text">
                      ndlkdnlkdnlkdnlkdnExecutive Functioning wkjdhfjw
                      scdscsdcsdcsdcsdcwdjbdwjkedbwjkebdwjewkdb
                      Dnakjdkjdajdhjdhajdhajdhajdhjdhaldhasjldhaldhljad
                    </div>
                    <img
                      class="scoreMeter-image"
                      src="../../../assets/images/apti-report/score3.png"
                      alt=""
                    />
                    <div class="scoreMeter-score">
                      <span> 4/10 </span>
                    </div>
                  </div> -->
                </div>
                <div class="pie-chart total-pie-chart">
                  <div class="pie-chart--title">Average Score</div>
                  <div class="pie-chart--image-container" [ngStyle]="{
                      'background-image': getFullCircleImage(
                        aptitudeReport.sectionDetailTotalScore.aptitude * 10
                      )
                    }">
                    <span class="pie-chart--score">{{
                      aptitudeReport?.sectionDetailTotalScore?.aptitude
                      | number: '1.0-1'
                      }}/10</span>
                  </div>
                </div>
              </div>
              <!-- Quant Section -->
              <div class="detailedScore-Card" *ngIf="aptitudeReport.sectionDetailedScores.quant.length > 0">
                <div class="detailedScore-Card-title">
                  <span class="HD-Title"> Numerical Reasoning </span>
                </div>
                <div class="score-meters">
                  <div class="score-meter-container" *ngFor="
                      let sectionData of aptitudeReport.sectionDetailedScores
                        .quant
                    ">
                    <h4 class="score-meter-title">
                      {{
                      gameNameToAttributes[
                      getOgGameName(sectionData.gameName)
                      ]
                      }}
                      
                    </h4>
                    <div class="score-meter-text" [innerHTML]="
                        reportContent[getOgGameName(sectionData.gameName)]
                      "></div>
                    <img class="scoreMeter-image" [src]="
                        urlHalfCircleImg +
                        (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                        '.png'
                      " alt="rating meter" />
                    <div class="scoreMeter-score">
                      <span> {{ sectionData.rating * (isTvsCredit ? 10 : 1)  }}/10{{isTvsCredit ? '0':''}} </span>
                    </div>
                  </div>
                </div>
                <div class="pie-chart total-pie-chart">
                  <div class="pie-chart--title">Average Score</div>
                  <div class="pie-chart--image-container" [ngStyle]="{
                      'background-image': getFullCircleImage(
                        aptitudeReport.sectionDetailTotalScore.quant * 10
                      ),
                      'height': isTvsCredit ? '38em' : '',
                      'width': isTvsCredit ? '38em' : ''
                    }">
                    <span class="pie-chart--score">{{

isTvsCredit ? round(aptitudeReport?.sectionDetailTotalScore?.quant * 10) : aptitudeReport?.sectionDetailTotalScore?.quant | number: '1.0-1'
                      }}/10{{isTvsCredit ? '0':''}}</span>
                  </div>
                </div>
              </div>
              <!-- Language Section -->
              <div class="detailedScore-Card" *ngIf="aptitudeReport.sectionDetailedScores.language.length > 0">
                <div class="detailedScore-Card-title">
                  <span class="HD-Title"> Verbal Reasoning  </span>
                </div>
                <div class="score-meters">
                  <div class="score-meter-container" *ngFor="
                      let sectionData of aptitudeReport.sectionDetailedScores
                        .language
                    ">
                    <h4 class="score-meter-title">
                      {{
                      gameNameToAttributes[
                      getOgGameName(sectionData.gameName)
                      ]
                      }}
                    </h4>

                    <div class="score-meter-text" [innerHTML]="
                        reportContent[getOgGameName(sectionData.gameName)]
                      "></div>
                    
                    <img class="scoreMeter-image" [src]="
                        urlHalfCircleImg +
                        (sectionData.rating <= 0 ? 1 : sectionData.rating) +
                        '.png'
                      " alt="rating meter" />
                    <div class="scoreMeter-score">
                      <span> {{ 
                        isTvsCredit ? round(sectionData.rating * 10) :
                        sectionData.rating }}/10{{isTvsCredit ? '0':''}} </span>
                    </div>
                  </div>
                </div>
                <div class="pie-chart total-pie-chart">
                  <div class="pie-chart--title">Average Score</div>
                  <div class="pie-chart--image-container" [ngStyle]="{
                      'background-image': getFullCircleImage(
                        aptitudeReport.sectionDetailTotalScore.language * 10
                      ),
                      'height': isTvsCredit ? '38em' : '',
                      'width': isTvsCredit ? '38em' : ''
                    }">
                    <span class="pie-chart--score">{{
                      isTvsCredit ? round(aptitudeReport?.sectionDetailTotalScore?.language * 10):
                      aptitudeReport?.sectionDetailTotalScore?.language | number: '1.0-1'
                      }}/10{{isTvsCredit ? '0':''}}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</ng-template>