import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../shared-services/api/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { ReportService } from './report.service';
import { SharedDataService } from '../shared-services/shared-data/shared-data.service';
import { COMPANY_ID } from '../shared-services/constants/globalEnums.enums';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  private _unsubscribe$ = new Subject<void>();

  reportContent = this._reportService.aptiReportContentCombined;
  gameNameToAttributes = this._reportService.gameNameToAttributes;
  urlHalfCircleImg = this._reportService.urlHalfCircleImg;
  aptitudeReport = {
    gameStatus: '',
    candidateName: '',
    assessmentTitle: '',
    assessmentType: 'Aptitude assessment',
    totalScore: 0,
    sectionCount: 0,
    sectionScoresTotal: {
      quant: undefined,
      language: undefined,
      aptitude: undefined,
    },
    sectionDetailedScores: {
      quant: [],
      language: [],
      aptitude: [],
    },
    sectionDetailTotalScore: {
      quant: 0,
      language: 0,
      aptitude: 0,
    },
  };

  companyData = {
    isProctoring: false,
    _id: '',
    name: '',
    customVerditBoundaries: [
      { title: 'Unlikely to succeed', maxValue: 35 },
      { title: 'Restricted likelihood of success', maxValue: 50 },
      { title: 'Likely to succeed', maxValue: 65 },
      { title: 'Very Likely to succeed', maxValue: 100 },
    ],
    mcqCount: 0,
  };
  candidateId = '';
  images = [];
  assessmentDetail = sessionStorage.Candidate
    ? JSON.parse(sessionStorage.Candidate)
    : null;
  constructor(
    private _api: ApiService,
    private _route: ActivatedRoute,
    protected sanitizer: DomSanitizer,
    private _reportService: ReportService,
    private _sharedService: SharedDataService,
  ) {}

  isThreePointReport = false;
  isPageOne = true;
  technicalAssessment = null;
  technicalAssessmentRating = null;
  isThermax =
    localStorage.user &&
    [COMPANY_ID.THERMAX].includes(JSON.parse(localStorage.user).company._id);
  isTvsCredit =
    localStorage.user &&
    [COMPANY_ID.TVS_CREDIT_CAMPUS].includes(
      JSON.parse(localStorage.user).company._id,
    );
  viewReport: {
    report_data: any;
    isThreePointReport: any;
    comp_framework?: any;
  } = {
    report_data: null,
    isThreePointReport: null,
  };

  brandReport: {
    report_data: any;
    isThreePointReport: any;
    comp_framework?: any;
  } = {
    report_data: null,
    isThreePointReport: null,
  };

  // aptitudeReport = null;
  mcqResultClassArray = [
    'vlow-ta',
    'vlow-ta',
    'vlow-ta',
    'low-ta',
    'low-ta',
    'medium-ta',
    'medium-ta',
    'high-ta',
    'high-ta',
    'vhigh-ta',
    'vhigh-ta',
  ];
  mcqResultArray = [
    'Very Low',
    'Very Low',
    'Very Low',
    'Low',
    'Low',
    'Medium',
    'Medium',
    'High',
    'High',
    'Very High',
    'Very High',
  ];

  // TODO Check here
  hideOrgComp = false;

  ngOnInit(): void {
    this.getReport();
    const res = this._sharedService.getCandidatesValue();
    if (res) {
      this.getCompanyDetail(res?.company_id);
    }
    this.getTechnicalAssessment();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  getOgGameName(gameName: string): string {
    return this._reportService.games.find((game) => gameName.includes(game));
  }

  accessQueryParams(): void {
    this._route.queryParams
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((params) => {
        this.candidateId = params.candidateId;
      });
  }
  downloadCandidateImages(images): void {
    images.forEach((element) => {
      this._api
        .downloadCandidateImages(element.file_name)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(
          (res) => {
            this.images.push({
              src: this.sanitizer.bypassSecurityTrustUrl(res?.image),
              caption: '',
            });
            this.images = this.images;
          },
          (_1) => {
            // this._toastr.showError('something went wrong');
          },
        );
    });
  }

  getCandidateData(): void {
    this.accessQueryParams();
    this._api
      .getCandidateData(this.candidateId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        //  this.images = res.images;
        // this.selectedCandidateData = res;
        if (res && res.images) {
          this.downloadCandidateImages(res.images);
        }
      });
  }

  getReport(): void {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;

    if (!JSON.parse(sessionStorage.getItem('Candidate'))) {
      this._api
        .getAssessmentDetail(assessmentId)
        .pipe(
          takeUntil(this._unsubscribe$),
          switchMap((value) => {
            this.assessmentDetail = value;
            this.getCompanyDetail(value.company_id);
            return value;
          }),
        )
        .subscribe((res) => {
          return res;
        });
    }

    const report$ = {
      behavioural: this._api.viewReport(email, assessmentId),
      aptitude: this._api
        .viewAptitudeReportForV1Candidates(email, assessmentId)
        .pipe(
          catchError((error) => {
            console.log('Error: ', error);
            return of(error);
          }),
          map((result) =>
            result &&
            result.gameStatus === 'completed' &&
            result.aptitudeScores.length
              ? result
              : null,
          ),
        ),
    };

    forkJoin(report$)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res: any) => {
        if (this.isTvsCredit) {
          this.viewReport = {
            comp_framework: res.behavioural?.comp_framework?.[0],
            report_data: res.behavioural?.report_data,
            isThreePointReport: res.behavioural?.isThreePointReport,
          };
          this.brandReport = {
            comp_framework: res.behavioural?.comp_framework?.[1],
            report_data: res.behavioural?.report_data,
            isThreePointReport: res.behavioural?.isThreePointReport,
          };
        } else {
          this.viewReport = res.behavioural;
        }
        if (typeof res.behavioural.comp_framework !== 'undefined') {
          this.hideOrgComp = true;
        }
        this.isThreePointReport = res.behavioural.isThreePointReport;
        // TODO remove commented purpose test #7
        // this.isThreePointReport = true;
        if (res.aptitude) {
          this.setAptitudeReportParameters(res.aptitude);
        } else {
          this.aptitudeReport = null;
        }
      });
  }

  setAptitudeReportParameters(aptitude: any) {
    this.aptitudeReport.candidateName = aptitude.name;
    this.aptitudeReport.assessmentTitle = aptitude.assessmentId.name;
    this.aptitudeReport.sectionCount = aptitude.processedScoreAvg.length;
    this.aptitudeReport.totalScore =
      aptitude.processedScoreAvg.reduce(
        (acc, current) => acc + current.score,
        0,
      ) / aptitude.processedScoreAvg.length;
    this.aptitudeReport.sectionScoresTotal.quant =
      aptitude.processedScoreAvg.filter((el) => el.gameName === 'quant')[0];
    this.aptitudeReport.sectionScoresTotal.language =
      aptitude.processedScoreAvg.filter((el) => el.gameName === 'language')[0];
    this.aptitudeReport.sectionScoresTotal.aptitude =
      aptitude.processedScoreAvg.filter((el) => el.gameName === 'aptitude')[0];

    const quantScores = aptitude.processedScores.filter(
      (el) => el.gameType === 'quant',
    );
    const aptitudeScores = aptitude.processedScores.filter(
      (el) => el.gameType === 'aptitude',
    );
    const languageScores = aptitude.processedScores.filter(
      (el) => el.gameType === 'language',
    );

    this.aptitudeReport.sectionDetailedScores.quant = quantScores;
    this.aptitudeReport.sectionDetailedScores.aptitude = aptitudeScores;
    this.aptitudeReport.sectionDetailedScores.language = languageScores;

    this.aptitudeReport.sectionDetailTotalScore.quant =
      quantScores.reduce((acc, current) => acc + current.rating, 0) /
      quantScores.length;
    this.aptitudeReport.sectionDetailTotalScore.aptitude =
      aptitudeScores.reduce((acc, current) => acc + current.rating, 0) /
      aptitudeScores.length;
    this.aptitudeReport.sectionDetailTotalScore.language =
      languageScores.reduce((acc, current) => acc + current.rating, 0) /
      languageScores.length;

    this.aptitudeReport.gameStatus = aptitude.gameStatus;
  }

  // TODO -test #5 --uncomment after test
  getReplacedText(text) {
    let strText = '';
    if (!text) {
      return;
    }
    if (this.viewReport.report_data.gender === 'Male') {
      strText = text
        .replace(/{@pronoun}/g, 'He')
        .replace(/{@spronoun}/g, 'he')
        .replace(/{@ppronoun}/g, 'His')
        .replace(/{@sppronoun}/g, 'his');
    } else if (this.viewReport.report_data.gender === 'Female') {
      strText = text
        .replace(/{@pronoun}/g, 'She')
        .replace(/{@spronoun}/g, 'she')
        .replace(/{@ppronoun}/g, 'Her')
        .replace(/{@sppronoun}/g, 'her');
    } else {
      strText = text
        .replace(
          /{@pronoun}/g,
          this.viewReport.report_data.candidate_first_name,
        )
        .replace(
          /{@spronoun}/g,
          this.viewReport.report_data.candidate_first_name,
        )
        .replace(/{@ppronoun}/g, 'Their')
        .replace(/{@sppronoun}/g, 'their');
    }
    strText = strText.replace(
      /{@name}/g,
      this.viewReport.report_data.candidate_first_name,
    );
    return strText;
  }

  getVerdictImage(averageScore, verdictName) {
    let totalSum = 0;
    let totalCount = 0;
    if (averageScore) {
      if (averageScore?.report_data) {
        // TODO looks similar cases --check this out
        if (
          averageScore?.report_data?.response &&
          averageScore?.report_data?.job_type !== 'Campus Recruitment'
        ) {
          totalSum += parseFloat(
            averageScore?.report_data?.response?.overall_average,
          );
          totalCount++;
        } else if (
          averageScore?.report_data?.job_type === 'Campus Recruitment' &&
          averageScore?.report_data?.response
        ) {
          totalSum += parseFloat(
            averageScore.report_data.response.overall_average,
          );
          totalCount++;
        }

        if (averageScore?.report_data?.mparam_response) {
          totalSum += parseFloat(
            averageScore.report_data.mparam_response.overall_average,
          );
          totalCount++;
        }
      }

      if (averageScore?.comp_framework) {
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average);
        totalCount++;
      }

      // totalSum = totalSum.toFixed(2);

      const totalAverage = (totalSum * 100) / totalCount;
      const verdict = this.calculateVerdict(totalAverage);

      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return true;
          } else {
            return false;
          }
          break;

        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return true;
          } else {
            return false;
          }
          break;
      }
    }
  }

  getReportName(profileName) {
    if (profileName === 'Customised Profile Fit') {
      return 'Profile Fit Report';
    }
    if (profileName === 'Campus Recruitment') {
      return 'Professional Efficacy Report';
    }
    if (profileName === 'Organisational Match') {
      return 'Organisational Compatibility Report';
    }
  }

  getOverallRatingPer() {
    let total = 0;
    let totalCount = 0;
    let tvsCreditScore = (this.isTvsCredit ? this.brandReport?.comp_framework?.overall_average_per:0)
    //
    if (this.viewReport.report_data.response !== undefined) {
      if (
        this.viewReport?.report_data?.response?.overall_average_per &&
        !isNaN(this.viewReport.report_data.response.overall_average_per)
      ) {
        total += parseFloat(
          this.viewReport.report_data.response.overall_average_per,
        );
        totalCount++;
      }
    }
    if (this.viewReport.report_data.mparam_response) {
      if (
        this.viewReport.report_data.mparam_response.overall_average_per &&
        !isNaN(this.viewReport.report_data.mparam_response.overall_average_per)
      ) {
        total += parseFloat(
          this.viewReport.report_data.mparam_response.overall_average_per,
        );
        totalCount++;
      }
    }
    if (this.viewReport.comp_framework) {
      if (
        this.viewReport.comp_framework.overall_average_per &&
        !isNaN(this.viewReport.comp_framework.overall_average_per)
      ) {
        total += parseFloat(this.viewReport.comp_framework.overall_average_per + tvsCreditScore);
        totalCount++;
      }
    }

    if(this.isTvsCredit){
      totalCount++
    }
    console.group({totalCount , total , tvsCreditScore})
    if (totalCount !== 0) {
      // total = total.toFixed(2);
      const averageScore = total / totalCount;
      // TODO -we hopefully dont require this
      // if (parseInt(averageScore) !== averageScore) {
      //   averageScore = averageScore.toFixed(1);
      // } else {
      //   averageScore = parseInt(averageScore);
      // }
      return averageScore % 1 !== 0 ? averageScore.toFixed(1) : averageScore;
    } else {
      return total;
    }
  }

  getOverallVerdict(averageScore, verdictName) {
    let totalSum = 0;
    let totalCount = 0;
    let tvsCreditScore = (this.isTvsCredit ? this.brandReport?.comp_framework?.overall_average:0)

    if (averageScore) {
      if (
        averageScore?.report_data?.response &&
        averageScore?.report_data?.job_type !== 'Campus Recruitment'
      ) {
        totalSum += parseFloat(
          averageScore?.report_data?.response?.overall_average,
        );
        totalCount++;
      } else if (
        averageScore?.report_data?.job_type === 'Campus Recruitment' &&
        averageScore?.report_data?.response
      ) {
        totalSum += parseFloat(
          averageScore?.report_data?.response?.overall_average + tvsCreditScore,
        );
       if(this.isTvsCredit){
        totalCount+=2;
       }else{
        totalCount++;
       }
      }

      if (averageScore?.report_data?.mparam_response) {
        totalSum += parseFloat(
          averageScore?.report_data?.mparam_response?.overall_average,
        );
        totalCount++;
      }

      if (averageScore.comp_framework) {
        totalSum += parseFloat(averageScore?.comp_framework?.overall_average + tvsCreditScore);
        totalCount++;
      }
      // TODO - check if calculations break
      // totalSum = totalSum.toFixed(2);
      const totalAverage = (totalSum * 100) / totalCount;
   
      const verdict = this.calculateVerdict(totalAverage);
      switch (verdictName) {
        case 'Unlikely to succeed':
          if (verdict === 'Unlikely to succeed') {
            return 'RSelect';
          }
          break;

        case 'Restricted likelihood of success':
          if (verdict === 'Restricted likelihood of success') {
            return 'RSelect';
          }
          break;

        case 'Likely to succeed':
          if (verdict === 'Likely to succeed') {
            return 'RSelect';
          }
          break;

        case 'Very Likely to succeed':
          if (verdict === 'Very Likely to succeed') {
            return 'RSelect';
          }
          break;
      }
    } else {
      return;
    }
  }

  getPageStyle() {
    if (this.viewReport) {
      if (this.viewReport?.report_data?.mparam_response) {
        if (this.viewReport.isThreePointReport) {
          return 'margin-managerial-data-three-scale';
        } else {
          return 'margin-managerial-data';
        }
      }
      if (this.viewReport?.comp_framework) {
        return 'margin-org-data';
      }
    }
  }

  headingAlignment(hideOrgComp, profileName, compFramework) {
    let className = '';
    if (this.hideOrgComp) {
      className = 'floatLeft firstBox-margin';
    } else {
      className = 'floatLeft';
    }
    if (
      profileName !== 'Senior Manager or Manager of Managers' &&
      profileName !== 'First-time or Junior Manager' &&
      !compFramework
    ) {
      // className += ' margin-85'; // commenting as we are using display flex instead of margins to center
    } else if (
      profileName !== 'Senior Manager or Manager of Managers' &&
      profileName !== 'First-time or Junior Manager' &&
      compFramework
    ) {
      // className += ' margin-120'; // commenting as we are using display flex instead of margins to center
    }

    return className;
  }

  safeHtml(explanation) {
    let strText = '';

    if (explanation) {
      if (this.viewReport.report_data.gender === 'Male') {
        strText = explanation
          .replace(/{@pronoun}/g, 'He')
          .replace(/{@spronoun}/g, 'he')
          .replace(/{@ppronoun}/g, 'His')
          .replace(/{@sppronoun}/g, 'his');
      } else if (this.viewReport.report_data.gender === 'Female') {
        strText = explanation
          .replace(/{@pronoun}/g, 'She')
          .replace(/{@spronoun}/g, 'she')
          .replace(/{@ppronoun}/g, 'Her')
          .replace(/{@sppronoun}/g, 'her');
      } else {
        strText = explanation
          .replace(
            /{@pronoun}/g,
            this.viewReport.report_data.candidate_first_name,
          )
          .replace(
            /{@spronoun}/g,
            this.viewReport.report_data.candidate_first_name,
          )
          .replace(/{@ppronoun}/g, 'Their')
          .replace(/{@sppronoun}/g, 'their');
      }
    }
    strText = strText.replace(
      /{@name}/g,
      this.viewReport.report_data.candidate_first_name,
    );
    return this.sanitizer.bypassSecurityTrustHtml(strText);
  }

  getFullCircleImage(score: number): string {
    return this._reportService.getFullCircleImage(score);
  }

  calculateVerdict(score: number): string {
    const customVerditBoundaries = this.companyData.customVerditBoundaries;
    let verdict = 'Pending';
    if (
      customVerditBoundaries &&
      customVerditBoundaries.length &&
      customVerditBoundaries.length > 3
    ) {
      // Handle the lowest boundry
      if (score < customVerditBoundaries[0].maxValue) {
        verdict = customVerditBoundaries[0].title;
      } else {
        for (let i = 0; i < customVerditBoundaries.length - 1; i++) {
          if (
            score >= customVerditBoundaries[i].maxValue &&
            score < customVerditBoundaries[i + 1].maxValue
          ) {
            verdict = customVerditBoundaries[i + 1].title;
          }
        }
        // Handle the highest boundry
        if (
          score >=
          customVerditBoundaries[customVerditBoundaries.length - 1].maxValue
        ) {
          verdict =
            customVerditBoundaries[customVerditBoundaries.length - 1].title;
        }
      }
    } else {
      if (score < 35) {
        verdict = 'Unlikely to succeed';
      }

      if (score >= 35 && score < 50) {
        verdict = 'Restricted likelihood of success';
      }

      if (score >= 50 && score < 65) {
        verdict = 'Likely to succeed';
      }

      if (score >= 65) {
        verdict = 'Very Likely to succeed';
      }
    }
    return verdict;
  }

  private getCompanyDetail(companyId): void {
    this._api
      .getCompanyData(companyId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        //for thermax unlogged in users
        this.isThermax = [COMPANY_ID.THERMAX].includes(res._id);
        this.isTvsCredit = [COMPANY_ID.TVS_CREDIT_CAMPUS].includes(res._id);
        this.companyData.mcqCount = res.mcqCount;
        this.companyData.isProctoring =
          res.Proctoring === 'On' || res.Proctoring === true;
        if (this.companyData.isProctoring) {
          this.getCandidateData();
        }
        if (
          res.customVerditBoundaries &&
          res.customVerditBoundaries.length > 3
        ) {
          this.companyData.customVerditBoundaries = res.customVerditBoundaries;
        }
      });
  }

  private getTechnicalAssessment(): void {
    const assessmentId = this._route.snapshot.params.id;
    const email = this._route.snapshot.params.email;

    this._api
      .getTechnicalAssessment(email, assessmentId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((res) => {
        this.technicalAssessment = res;
        if (
          res.assessmentId &&
          res.assessmentId.department &&
          res.assessmentId.department.benchmarks &&
          res.assessmentId.department.benchmarks.length > 0
        ) {
          if (res.rating) {
            this.technicalAssessmentRating = res.rating;
          } else {
            res.assessmentId.department.benchmarks.forEach((bench) => {
              if (res.totalScore >= bench.benchmarkValue) {
                this.technicalAssessmentRating = bench.order;
              }
            });
          }
        }
      });
  }

  round(val) {
    return Math.round(val);
  }
}
